<template>
  <dx-util-popup
    ref="shipmentBatchFormPopupRef"
    :show-close-button="true"
    :drag-enabled="true"
    :close-on-outside-click="false"
    :show-title="true"
    :full-screen="false"
    height="auto"
    :width="calculateWidth"
    :title="shipmentBatchPopupTitle"
    @shown="onShown"
    @hidden="onHidden"
  >
    <div>
      <dx-util-validation-group ref="shipmentBatchFormRef">
        <div class="d-flex py-half">
          <div class="col-6 px-1">
            <dx-util-number-box
              ref="shipmentBatchAccountRef"
              v-model="accountNo" label="Account or Suite No"
              :read-only="onlyNotesUpdate"
              label-mode="floating"
              @enter-key="getCompanyByAccountNo"
              @key-down="onKeyDown"
              @initialized="setFocus"
            />
          </div>
          <div class="col-6 px-1">
            <dx-util-text-box
              v-if="true"
              v-model="companyName" label="Company Name"
              label-mode="floating"
              :read-only="onlyNotesUpdate"
            >
              <dx-util-validator>
                <dx-util-required-rule message="Company is required" />
              </dx-util-validator>
            </dx-util-text-box>
            <dx-util-select-box
              v-if="false"
              ref="companySelectBox"
              v-model="companyName" label="Company"
              :data-source="suggestedCompanies"
              label-mode="floating"
              display-expr="text"
              value-expr="value"
              :accept-custom-value="true"
              :opened="isSelectBoxOpened"
              :search-enabled="true"
              @custom-item-creating="searchCompany"
              @selection-changed="onValueChanged"
            >
            <dx-util-validator>
                <dx-util-required-rule message="Company is required" />
              </dx-util-validator>
            </dx-util-select-box>
            <!-- TODO: Ask company search feature usage -->
            <vue-autosuggest
              v-if="false"
              :suggestions="filteredOptions"
              :limit="10"
              :value="companyName"
              :input-props="inputPropsCompany"
              @selected="onSelected"
              @input="searchCompany"
              @keydown.esc="onClose"
            >
              <template slot-scope="{suggestion}">
                <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
              </template>
            </vue-autosuggest>
          </div>
        </div>
        <div class="d-flex py-half">
          <div class="col-6 px-1">
            <dx-util-select-box
              v-model="shipmentBatchForm.storeId" label="Stores"
              :read-only="onlyNotesUpdate"
              :data-source="stores"
              label-mode="floating"
              display-expr="text"
              value-expr="value"
            >
            <dx-util-validator>
                <dx-util-required-rule message="Store is required" />
              </dx-util-validator>
            </dx-util-select-box>
          </div>
          <div class="col-6 px-1">
            <dx-util-select-box
              v-model="shipmentBatchForm.warehouseId" label="Warehouse"
              :read-only="onlyNotesUpdate"
              label-mode="floating"
              :data-source="warehouseOptions"
              display-expr="text"
              value-expr="value"
            >
              <dx-util-validator>
                <dx-util-required-rule message="Warehouse is required" />
              </dx-util-validator>
            </dx-util-select-box>
          </div>
        </div>
        <div class="d-flex py-half">
          <div class="col-12 px-1">
            <dx-util-text-box
              v-model="shipmentBatchForm.name" label="Batch Name"
              :read-only="onlyNotesUpdate"
              label-mode="floating"
            >
              <dx-util-validator>
                <dx-util-required-rule message="Batch name is required" />
              </dx-util-validator>
            </dx-util-text-box>
          </div>
        </div>
        <div class="d-flex py-half">
          <div class="col-6 px-1">
            <dx-util-select-box
              v-model="shipmentBatchForm.status" label="Status"
              :read-only="onlyNotesUpdate"
              label-mode="floating"
              :data-source="statusOptions"
              display-expr="text"
              value-expr="value"
            >
              <dx-util-validator>
                <dx-util-required-rule message="Status is required" />
              </dx-util-validator>
            </dx-util-select-box>
          </div>
          <div class="col-6 px-1">
            <dx-util-number-box
              v-model="shipmentBatchForm.itemCount" label="Item Count"
              :read-only="onlyNotesUpdate"
              label-mode="floating"
              :min="0"
            >
              <dx-util-validator>
                <dx-util-required-rule message="Item count is required" />
              </dx-util-validator>
            </dx-util-number-box>
          </div>
        </div>
        <div class="d-flex py-half">
          <div class="col-12 px-1">
            <dx-util-text-area
              v-model="shipmentBatchForm.notes" label="Notes"
              label-mode="floating"
              :max="250"
            />
          </div>
        </div>
      </dx-util-validation-group>
      <div class="text-right m-1">
          <dx-util-button type="danger" text="Cancel" class="mr-1" style="min-width:100px" @click="onClose" />
          <dx-util-button type="success" text="Save" class="" style="min-width:100px" @click="onSubmit" />
        </div>
    </div>
  </dx-util-popup>
</template>

<script>
import { Notify } from '@robustshell/utils/index'
import { VueAutosuggest } from 'vue-autosuggest'
import outboundShippingService from '@/http/requests/outbound/outboundShippingService'
import companyService from '@/http/requests/company/companyService'
import commonEntityService from '@/http/requests/common/commonEntityService'
import { useWindowSize } from '@vueuse/core'

export default {
  components: {
    VueAutosuggest,
  },
  props: {
    componentId: {
      type: String,
      required: true,
    },
    selectedRow: {
      type: Object,
      required: true,
    },
    warehouses: {
      type: Array,
      required: true,
    },
    statuses: {
      type: Array,
      required: true,
    },
    onlyNotesUpdate: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      shipmentBatchPopupTitle: '',
      shipmentBatchForm: {
        id: null,
        name: '',
        companyId: null,
        storeId: null,
        itemCount: 0,
        status: '',
        warehouseId: null,
        notes: '',
      },
      warehouseOptions: [],
      statusOptions: [],
      companyName: '',
      accountNo: null,
      companyId: 0,
      suggestedCompanies: [],
      filteredOptions: [],
      isSelectBoxOpened: false,
      limit: 10,
      inputPropsCompany: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: 'Company Name',
      },
      stores: [],
    }
  },
  setup() {
    const { width: windowWidth } = useWindowSize()
    return { windowWidth }
  },
  computed: {
    calculateWidth() {
      let adjustedWidth = ''
      if (this.windowWidth < 400) {
        adjustedWidth = '95vw'
      } if (this.windowWidth >= 400 && this.windowWidth <= 1200) {
        adjustedWidth = '80vw'
      } if (this.windowWidth > 1200 && this.windowWidth <= 1400) {
        adjustedWidth = '60vw'
      } if (this.windowWidth > 1400) {
        adjustedWidth = '40vw'
      }
      return adjustedWidth
    },
  },
  watch: {
    componentId: {
      immediate: false,
      handler() {
        this.$nextTick(() => {
          this.$refs.shipmentBatchFormPopupRef.instance.show()
          if (this.selectedRow.id === 0 || this.selectedRow.id === null) {
            this.$refs.shipmentBatchFormRef.instance.reset()
            this.shipmentBatchForm.status = 'IN_PREP'
            this.setFocus()
          }
        })
        if (this.selectedRow.id !== 0 || this.selectedRow.id !== null) {
          this.companyId = this.selectedRow.companyId
          this.shipmentBatchForm.storeId = this.selectedRow.storeId
          this.shipmentBatchForm.id = this.selectedRow.id
          this.shipmentBatchForm.name = this.selectedRow.batchName
          this.shipmentBatchForm.itemCount = this.selectedRow.itemCount
          this.shipmentBatchForm.status = this.selectedRow.status
          this.shipmentBatchForm.warehouseId = this.selectedRow.warehouseId
          this.shipmentBatchForm.notes = this.selectedRow.notes
          this.shipmentBatchForm.storeId = this.selectedRow.storeId
          this.companyName = this.selectedRow.companyName
        }
      },
    },
    selectedRow: {
      immediate: true,
      handler() {

      },
    },
  },
  methods: {
    getCompanyByAccountNo() {
      const fourDigit = 1000
      if (!this.accountNo || this.accountNo === null || this.accountNo === 0
      || this.accountNo === '' || this.accountNo === undefined || this.accountNo < fourDigit) {
        Notify.error('Please enter a valid account no')
        return
      }
      companyService.fetchByAccountNo(this.accountNo).then(result => {
        this.companyId = result.data.id
        this.companyName = result.data.name
      }).then(() => {
        this.getStores()
      })
    },
    getStores() {
      const companyId = this.companyId
      if (companyId === '' || companyId === null || typeof companyId === 'undefined' || companyId === 0) {
        return
      }
      this.stores.splice(0, this.stores.length)
      commonEntityService.fetchStoresByParentCompany(companyId).then(result => {
        const data = result.data
        data.forEach(element => {
          this.stores.push({ value: element.id, text: element.name })
        })
        if (this.stores.length === 1) {
          this.shipmentBatchForm.storeId = this.stores[0].value
        }
      })
    },
    searchCompany(query) {
      if (query.text === undefined || query.text === '' || query.text.length < 4) {
        return
      }
      const params = { name: query.text, type: 'customer' }
      companyService.fetchAllMatchingNameAndType(params).then(response => {
        this.suggestedCompanies.splice(0, this.suggestedCompanies.length)
        this.suggestedCompanies = response.data.map(el => ({
          text: el.name,
          value: el.id,
          accountNo: el.accountNo,
        }))
        // this.suggestedCompanies.push(...response.data)
        // this.filteredOptions = [{
        //  data: this.suggestedCompanies,
        // }]
      }).then(() => {
        this.isSelectBoxOpened = true
      })
    },
    onValueChanged(option) {
      this.companyId = option.selectedItem.value
      this.companyName = option.selectedItem.text
      this.accountNo = option.selectedItem.accountNo
      this.getStores()
    },
    onSelected(option) {
      this.companyId = option.item.id
      this.companyName = option.item.name
      this.accountNo = option.item.accountNo
      this.getStores()
    },
    onSubmit() {
      const validationResult = this.$refs.shipmentBatchFormRef.instance.validate()
      if (validationResult.isValid) {
        this.shipmentBatchForm.companyId = this.companyId
        if (this.shipmentBatchForm.id > 0) {
          outboundShippingService.updateBatch(this.shipmentBatchForm).then(() => {
            this.$emit('on-item-saved')
            this.onClose()
          })
        } else {
          this.shipmentBatchForm.id = ''
          outboundShippingService.createBatch(this.shipmentBatchForm).then(() => {
            this.$emit('on-item-saved')
            this.onClose()
          })
        }
      } else {
        Notify.warning('Please check the fields.')
      }
    },
    resetForm() {
      this.shipmentBatchForm = {}
      this.shipmentBatchForm.id = null
      this.shipmentBatchForm.name = ''
      this.shipmentBatchForm.itemCount = 0
      this.shipmentBatchForm.status = ''
      this.shipmentBatchForm.warehouseId = null
      this.shipmentBatchForm.notes = ''
      this.shipmentBatchForm.storeId = null
      this.stores = []

      this.companyName = null
      this.accountNo = null
      this.companyId = null
      this.suggestedCompanies = []
      this.filteredOptions = []
    },
    onShown() {
      this.shipmentBatchPopupTitle = `Shipment Batch Form${this.selectedRow.id !== 0 ? `: #${this.selectedRow.id}` : ''}`
      this.getStores()
      this.statusOptions = this.statuses.filter(el => el.value !== 'ALL')

      this.warehouseOptions = this.warehouses.filter(el => el.value !== 0)
      if (this.warehouseOptions.length === 1) {
        this.shipmentBatchForm.warehouseId = this.warehouseOptions[0].value
      }
    },
    onClose() {
      this.$refs.shipmentBatchFormRef.instance.reset()
      this.$refs.shipmentBatchFormPopupRef.instance.hide()
    },
    onHidden() {
      this.resetForm()
    },
    onKeyDown(e) {
      const escapeKey = 27
      if (e.event.which === escapeKey) {
        this.onClose()
      }
    },
    setFocus(e) {
      setTimeout(() => {
        this.$refs.shipmentBatchAccountRef.instance.focus()
      }, 600)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
