<template>
  <div class="">
    <div v-if="!isEditable">
      <div v-if="row.value" class="text-center">
        <span>
          {{ row.value }}
        </span>
        <p-icon role="button" name="bi-pencil-square" class="text-primary ml-1" @click.native="isEditable = true" />
      </div>
      <div v-else>
        <dx-util-button text="Set Reference ID" :element-attr="assignBtnOptions" type="success" hint="Set Reference ID for LTL" style="max-width: 140px;" @click="isEditable = true" />
      </div>
    </div>
    <div v-else>
      <dx-util-text-box
      :ref="`textbox-referenceId-${row.data.id}`"
      :value="row.value"
      width="140"
      @content-ready="setEditable"
      @enter-key="handleSubmit"
      @key-down="onKeyDown"
      @value-changed="updateRefId"
    >
      <dx-text-box-button
        :name="`referenceId-${row.data.id}`"
        :options="{
          icon: 'save',
          type: 'success',
          elementAttr: { id: `referenceId-${row.data.id}` },
          onClick: (e) => handleSubmit(e)
          }"
        location="after"
      />
  </dx-util-text-box>
    </div>
  </div>
</template>

<script>
import { DxButton as DxTextBoxButton } from 'devextreme-vue/text-box'
import fbaShipmentService from '@/http/requests/outbound/fbaShipmentService'

export default {
  components: {
    'dx-text-box-button': DxTextBoxButton,
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEditable: false,
      assignBtnOptions: {
        class: 'btn-assign',
      },
      form: {
        id: null,
        referenceId: '',
      },
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  mounted() {
    this.form.referenceId = this.row.value
  },
  methods: {
    updateRefId(row) {
      this.form.referenceId = row.value
    },
    setEditable(e) {
      e.component.focus()
    },
    async handleSubmit(e) {
      this.form.id = this.row.data.id
      await fbaShipmentService.updateSingleBatch(this.form)
      this.$emit('emit-reference-id-updated')
      this.isEditable = true
      // this.resetForm()
    },
    onKeyDown(e) {
      const escapeKey = 27
      if (this.isEditable && e.event.which === escapeKey) {
        this.isEditable = false
        // this.resetForm()
      }
    },
    resetForm() {
      this.form.id = null
      this.form.referenceId = ''
    },
  },

}
</script>

<style lang="scss" scoped>
  .btn-assign{
    color: #fff !important;
    width: 100% !important;
    .dx-button-content {
      padding: 4px 4px 4px !important;
    }
  }

</style>
