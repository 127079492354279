import { render, staticRenderFns } from "./ShipmentBatches.vue?vue&type=template&id=75c94776"
import script from "./ShipmentBatches.vue?vue&type=script&lang=js"
export * from "./ShipmentBatches.vue?vue&type=script&lang=js"
import style0 from "./ShipmentBatches.vue?vue&type=style&index=0&id=75c94776&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports