<template>
    <!--Begin::Breadcrumb-->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 px-0">
          <dx-data-grid
            id="shipmentBatches"
            ref="shipmentBatchesRef"
            :height="setHeight"
            :data-source="ShipmentBatchsDataSource"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :show-borders="true"
            :row-alternation-enabled="false"
            :hover-state-enabled="true"
            :customize-columns="customizeColumns"
            @initialized="onInitialized"
            @option-changed="onOptionChanged"
            @content-ready="onContentReady"
            @toolbar-preparing="onToolbarPreparing($event)"
            @editor-preparing="onEditorPreparing"
          >
            <!--region    ::DataGird base components -->
            <dx-state-storing
              :enabled="true"
              type="custom"
              :custom-load="loadDataGridState"
              :custom-save="saveDataGridState"
            />
            <dx-sorting mode="single" />
            <dx-remote-operations :paging="true" :filtering="false" :sorting="true" :summary="false" />
            <dx-search-panel :text="searchText" :visible="true" :width="200" placeholder="Search..." />
            <template #buttons="{ data }">
              <div>
                <div v-for="item in data" :key="item.id" class="">
                  <dx-util-button :text="item.name" :icon="item.icon" class=" w-100 text-left" styling-mode="text" @click="setActionByItem(item)" />
                </div>
              </div>
            </template>
            <dx-paging :page-size="pagerOptions.pageSize" />
            <dx-pager
              :allowed-page-sizes="pagerOptions.allowedPageSizes"
              :show-info="pagerOptions.showInfo"
              :show-navigation-buttons="pagerOptions.showNavigationButtons"
              :show-page-size-selector="pagerOptions.showPageSizeSelector"
              :visible="pagerOptions.visible"
            />
            <dx-selection
              show-check-boxes-mode="always"
              select-all-mode="page"
              :allow-select-all="true"
              :width="10"
              mode="multiple"
            />
            <dx-column-chooser :enabled="true" mode="select" title="Column Chooser" />
            <!--endregion ::DataGird base components -->
            <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="160" :fixed="true" fixed-position="left" />
            <template #itemActions="{data}">
              <div>
                <dx-util-button
                  type="default"
                  icon="bi bi-grid-3x3-gap-fill"
                  class="mr-half"
                  hint="Item List"
                  @click="onBatchNameLinkClick(data, false)"
                />
                <dx-util-button
                  :disabled="allowAddCostToBatch(data)"
                  type="success"
                  icon="bi bi-cash-stack"
                  class="mr-half"
                  hint="Batch charges"
                  @click="onAddCostToBatch(data)"
                />
                <dx-util-drop-down-button
                  id="editActions"
                  ref="quantityEditActionsRef"
                  :visible="hasPermission || isIndividualTenant"
                  :split-button="false"
                  :use-select-mode="false"
                  :items="editActions"
                  :drop-down-options="{ width: 170 }"
                  styling-mode="contained"
                  class="mr-half"
                  icon="menu"
                  display-expr="name"
                  key-expr="id"
                  item-template="item-temp"
                >
                  <template #item-temp="{data: item}">
                    <dx-util-button
                      :id="item.btnId"
                      :text="item.name"
                      :icon="item.icon"
                      :disabled="isActionDisabled(data, item.id)"
                      :visible="item.visible"
                      :class="item.class"
                      :element-attr="item.elementAttr"
                      width="170"
                      @click="onEditActionClink($event, data, item.id)"
                    />
                  </template>
                </dx-util-drop-down-button>
              </div>
            </template>
            <dx-column data-field="shipmentPlanName" cell-template="tempShipmentPlanName" caption="Shipment Plan Name" :allow-sorting="true" />
            <template #tempShipmentPlanName="{ data }">
              <div>
                <a href="#" @click="onShipmentPlanNameLinkClick($event, data)">
                  <span>{{ data.value }}</span>
                </a>
              </div>
            </template>
            <dx-column data-field="batchName" cell-template="tempBatchName" caption="Shipment Id" :allow-sorting="true" />
            <dx-column :visible="hasPermission" data-field="accountNo" caption="Acc #" :allow-sorting="false" cell-template="accountCell" />
            <dx-column :visible="hasPermission" data-field="suiteNo" caption="Suite #" :allow-sorting="false" cell-template="accountCell" />
            <dx-column :visible="hasPermission" data-field="companyName" caption="Company" :allow-sorting="false" />
            <template #accountCell="{ data }">
              <a href="#" @click="onFilterByAccountNoLink($event, data)">
                <span>{{ data.value }}</span>
              </a>
            </template>
            <dx-column data-field="storeName" caption="Store" :allow-sorting="false" />
            <dx-column data-field="skuCount" caption="SKU QTY" />
            <dx-column data-field="itemCount" caption="Item QTY" />
            <dx-column data-field="deliveredQty" caption="Delivered" cell-template="deliveredQtyTemplate" />
            <template #deliveredQtyTemplate="{ data }">
              <div>
                <span :class="getItemsDeliveredStatus(data)" class="d-block badge">
                  {{ data.value ? data.value : 0 }}
                </span>
              </div>
            </template>
            <dx-column data-field="foundQty" :visible="showFoundQty" cell-template="foundQtyCell" />
            <template #foundQtyCell="{ data }">
            <span class="d-block badge badge-light-success">
              {{ data.value }}
            </span>
          </template>
            <dx-column data-field="status" caption="Prep Status" :allow-sorting="false" cell-template="statusCell" />
            <template #statusCell="{ data }">
              <span class="d-block badge" :class="getFormated(data.value).variant">
                {{ getFormated(data.value).text }}
              </span>
            </template>
            <dx-column data-field="amzStatus" caption="Amazon Status" cell-template="amzStatusCell" />
            <template #amzStatusCell="{ data }">
              <span v-if="data.value" class="d-block badge" :class="getAmzFormated(data.value).variant">
                {{ getAmzFormated(data.value).text }}
              </span>
            </template>
            <dx-column data-field="creationTime" caption="Created At" data-type="date" />
            <!-- <dx-column data-field="receiveTime" caption="Received At" data-type="date" :allow-sorting="false" /> -->
            <dx-column data-field="shipTime" caption="Shipped At" data-type="date" :allow-sorting="false" />
            <dx-column data-field="pickupDate" data-type="date" />
            <!-- <dx-column data-field="warehouseName" caption="Warehouse" :allow-sorting="false" /> -->
            <dx-column data-field="destCode" caption="Dest" cell-template="destCodeCell" />
            <template #destCodeCell="{ data }">
              <span v-if="data.value" class="d-block badge badge-secondary">
                {{ data.value }}
              </span>
            </template>
            <dx-column data-field="q" :visible="false" />
            <dx-column data-field="source" cell-template="sourceQtyTemplate" />
            <template #sourceQtyTemplate="{ data }">
              <div>
                <span
                  v-if="data.value === 'FBA_FORCE'"
                  class="d-block badge badge-light-primary"
                  >
                  {{ data.value }}
                </span>
                <span
                  v-else-if="data.value === 'WFS_FORCE'"
                  class="d-block badge badge-light-warning"
                  >
                  {{ data.value }}
                </span>
                <span
                  v-else
                  class="d-block badge badge-light-secondary"
                  >
                  {{ data.value }}
                </span>
              </div>
            </template>
            <dx-column
              data-field="fileLink"
              caption="BOL"
              alignment="center"
              cell-template="tempFileLink"
            />
            <template #tempFileLink="{data}">
              <div v-if="data.value">
                <dx-util-button
                  text="Files" styling-mode="outlined"
                  type="success"
                  hint="Download BOL file"
                  @click="onUploadBOL(data.data)"
                />
              </div>
            </template>
            <dx-column data-field="batchChargesTotal" caption="Batch Charges" cell-template="batchChargesTemplate" :visible="isServingTenant" />
            <dx-column data-field="shippingCost" caption="Shipping Cost" cell-template="batchChargesTemplate" />
            <dx-column data-field="placementFee" cell-template="batchChargesTemplate" />
            <template #batchChargesTemplate="{ data }">
              {{ getCurrencyFormat(data) }}
            </template>
            <dx-column data-field="shipmentType" caption="Ship Type" cell-template="shipTypeTemplate" :width="82" />
            <template #shipTypeTemplate="{ data }">
              <div class="badge d-block" :class="getShipmentTypeFormat(data.value)">
                {{ data.value }}
              </div>
            </template>
            <dx-column data-field="proNumber" />
            <dx-column data-field="referenceId" caption="Reference Id" cell-template="referenceIdTemplate" width="160" />
            <template #referenceIdTemplate="{ data }">
              <div v-if="data.data.freightType === 'AMAZON_FREIGHT' && data.data.shipmentType === 'LTL'">
                <reference-id-setter :row="data" @emit-reference-id-updated="refreshAfterSettingRefID" />
              </div>
            </template>
            <dx-column
              data-field="invoiceId"
              caption="Invoice"
              cell-template="tempInvoice"
              :visible="isServingTenant"
            />
            <dx-column
              data-field="downloadCount"
              caption="Download"
              alignment="left"
              :width="160"
              cell-template="downloadCountTemplate"
              :allow-filtering="false"
            />
            <template #downloadCountTemplate="{ data }">
              <dx-util-drop-down-button
                v-if="data.data.parentStatus === 'closed'"
                id="downloadActions"
                ref="downloadActionsRef"
                :split-button="false"
                :use-select-mode="false"
                :items="downloadActions"
                :show-arrow-icon="false"
                :text="downloadCountButtonName(data)"
                :type="'clear'"
                styling-mode="outlined"
                hint="Download IL/Seller Board formatted file"
                class="d-block"
                :class="{'green-color': data.value < 1}"
                display-expr="name"
                key-expr="id"
                item-template="item-temp"
              >
              <template #item-temp="{data: item}">
                <dx-util-button
                  :id="item.btnId"
                  :text="item.name"
                  :icon="item.icon"
                  :class="item.class"
                  @click="onDownloadItemClick($event, data, item.id)"
                />
              </template>
            </dx-util-drop-down-button>
            </template>
            <dx-column data-field="notes" caption="Notes" :width="60" alignment="center" :allow-sorting="false" cell-template="noteTemplate" />
            <template #noteTemplate="{ data }">
              <div>
                <div :id="`notes-${data.data.id}`" class="d-inline" @mouseleave="togglePopover(data)" @mouseenter="togglePopover(data)">
                  <p-icon v-if="data.value" name="bi-sticky-fill" color="#FFFF99" size="16px" @click.native="togglePopover" />
                </div>
                <p-icon v-if="userCompanyType === 'customer'" role="button" name="bi-pencil-square" class="text-primary ml-half" @click.native="updateNote(data.data)" />
              </div>
            </template>
            <template #tempBatchName="{ data }">
              <!-- <div v-if="!hasPermission">
                <b-link :href="`https://sellercentral.amazon.com/gp/fba/inbound-shipment-workflow/index.html/ref=ag_fbaisw_name_fbasqs#${data.value}/summary/tracking`" target="_blank">
                  <b-badge
                    v-b-tooltip.d800.hover.left.v-secondary="'Go to Amazon'"
                    pill
                    :variant="`light-info`"
                    class="text-capitalize d-block"
                  >
                    {{ data.value }}
                    <feather-icon
                      class="ml-1"
                      icon="ExternalLinkIcon"
                      size="18"
                    />
                  </b-badge>
                </b-link>
              </div> -->
              <div>
                <div role="button" class="text-info" @click="onBatchNameLinkClick(data, true)">
                  {{ data.value }}
                </div>
              </div>
            </template>
            <template #tempInvoice="{data}">
              <router-link
                v-if="data.value"
                :to="{ name: 'apps-invoice-preview', params: { id: data.value } }"
              >
                <span class="text-capitalize d-block badge badge-light-success badge-pill">
                  Invoice
                </span>
              </router-link>
            </template>
            <template #filterToolbar>
              <div class="d-flex flex-row align-items-center">
                <div class="mr-1">
                  <dx-util-text-box
                    v-model="accountNo"
                    :visible="hasPermission"
                    :show-clear-button="true"
                    mode="text"
                    placeholder="Account or Suite No"
                    @key-down="onFilterByAccountNo"
                    @value-changed="onFilterByAccountNo"
                  />
                </div>
                <div class="mr-1">
                  <dx-util-select-box
                    v-model="selectedWarehouse"
                    :data-source="warehouses"
                    display-expr="text"
                    value-expr="value"
                    @value-changed="searchByFilter"
                  />
                </div>
                <div class="mr-1">
                  <dx-util-select-box
                    v-model="selectedStore"
                    :data-source="stores"
                    display-expr="text"
                    value-expr="value"
                    @value-changed="searchByFilter"
                  />
                </div>
                <div class="mr-1">
                  <dx-util-select-box
                    v-model="selectedStatus"
                    :data-source="statuses"
                    display-expr="text"
                    value-expr="value"
                    @value-changed="searchByFilter"
                  />
                </div>
                <div class="mr-1">
                  <dx-util-select-box
                    v-model="selectedAmzStatus"
                    :data-source="amzStatuses"
                    display-expr="text"
                    value-expr="value"
                    @value-changed="searchByFilter"
                  />
                </div>
                <div class="mr-1">
                  <dx-util-select-box
                    v-model="selectedDateRange"
                    :data-source="dateRangesList"
                    :drop-down-options="{width: '180px'}"
                    display-expr="text"
                    value-expr="value"
                    @value-changed="setDateRanges"
                  />
                </div>
                <div v-show="isCustomDateRange" class="mr-1">
                  <dx-util-date-box v-model="beginDate" :max="endDate" type="date" @value-changed="searchByFilter" />
                </div>
                <div v-show="isCustomDateRange" class="mr-1">
                  <dx-util-date-box v-model="endDate" :min="beginDate" type="date" @value-changed="searchByFilter" />
                </div>
                <div class="d-flex flex-column">
                  <div class="text-sm-left text-center text-nowrap">
                    Batch: {{ counts.batchCount }}
                  </div>
                  <div class="text-sm-left text-center text-nowrap">
                    Items: {{ counts.itemCount }}
                  </div>
                </div>
              </div>
            </template>
          </dx-data-grid>
      </div>
      </div>
    <shipment-batch-createoredit
      :id="selectedRow.id"
      :component-id="shipmentBatchCreateOrEditId"
      :only-notes-update="onlyNotesUpdate"
      :selected-row="selectedRow"
      :warehouses="warehouses"
      :statuses="statuses"
      @on-item-saved="onRowChanged"
    />
    <add-cost-to-invoice :component-id="addCostToInvoiceComponentId" :store-name="storeName" :batch-name="batchName" :batch-id="batchId" :disabled="disabledAddCost" />
    <!-- #region Shipment Items -->
    <shipment-items :component-id="shippedItemsComponentId" :batch-id="batchId" :batch-items="showBatchItems" :batch-name="batchName" />
    <!-- #endregion Shipment Items -->
    <!-- #region Print Label -->
    <dx-util-popup
        ref="printShipmentBacthPopupRef"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-close-button="true"
        :show-title="true"
        :title="batchName"
        :full-screen="true"
        content-template="popup-content"
        @shown="onShownPrintShipmentBacthPopup"
      >
        <template #popup-content>
        <dx-util-scroll-view height="100%" width="100%">
          <print-shipment-bacth :shipment-batch-id="batchId" :shipment-batch-name="batchName" />
        </dx-util-scroll-view>
        </template>
      </dx-util-popup>
    <!-- #endregion Print Label -->
    <!-- #region Notification Preferences -->
    <dx-util-popup
      ref="notificationPreferencesPopupRef"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="true"
      :show-title="true"
      title="Notification Preferences"
      height="70%"
      width="70%"
      content-template="popup-content"
    >
      <template #popup-content>
        <dx-util-scroll-view height="100%" width="100%">
          <notification-preferences />
        </dx-util-scroll-view>
      </template>
    </dx-util-popup>
    <!-- #endregion Preferences Management -->
    <!-- #region File Upload -->
    <dx-util-popup
      ref="fileUploadPopupRef"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-close-button="true"
      :show-title="true"
      :title="bolUpload.batchName"
      height="70%"
      width="70%"
      content-template="popup-content"
      :on-hiding="onCloseUploadPopupRef"
    >
      <template #popup-content>
        <dx-util-scroll-view height="100%" width="100%">
          <div>
            <service-folder-file-manager
              :company-id="bolUpload.companyId"
              :upload-document-class="bolUpload.documentClass"
              :upload-document-classes="bolUpload.documentClasses"
              :entity-folder-id="bolUpload.batchId"
              :service-folder="bolUpload.serviceName"
              :from-shipment-batch="true"
            />
          </div>
        </dx-util-scroll-view>
      </template>
    </dx-util-popup>
    <!-- #endregion File Upload -->
    <dx-util-popover
        :target="hoveredItem"
        position="right"
        :defer-rendering="false"
        :close-on-outside-click="true"
        :width="400"
        :visible="isPopoverVisible"
        :show-close-button="false"
      >
      <template>
        <div>
          {{ hoveredNote }}
        </div>
      </template>
    </dx-util-popover>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import outboundShippingService from '@/http/requests/outbound/outboundShippingService'
import predefinedDateRangesEnum, { getPredefinedDateRangesList } from '@/enums/predefinedDateRanges.enum.js'
import useDateRanges from '@/@core/composables/useDateRanges.js'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import { formatDate, currencyFormatter } from '@core/utils/filter'
import {
  isValidAsin,
  isValidUpc,
} from '@/views/apps/ship/validationItem'
import isEmpty from 'lodash/isEmpty'
import { Notify } from '@robustshell/utils'
import { v4 as uuidv4 } from 'uuid'
import settingsService from '@/http/requests/common/settings'
import SettingsTypeEnum from '@/enums/settingsTypeEnum'
import referenceTypeEnum from '@/enums/referenceTypeEnum'
import { DataGridNamesEnum } from '@/enums'
import useCurrentUser from '@/libs/app/current-user'
import ShipmentBatchFilter from '@/http/models/search-filters/ShipmentBatchFilter'
import { getListAmzShipmentStatusEnum } from '@/enums/amzShipmentStatusEnum.js'
import ShipmentBatchCreateOrEdit from './ShipmentBatchCreateOrEdit.vue'
import AddCostToBatch from './AddCostToBatch.vue'
import ShipmentItems from './ShipmentItems.vue'
import PrintShipmentBacth from './components/PrintShipmentBacth.vue'
import NotificationPreferences from './components/NotificationPreferences.vue'
import { ShipmentBatchsDataSource } from './shipmentBatcheStore'
import ReferenceIdSetter from './components/ReferenceIdSetter.vue'

const today = new Date()
const last30Days = new Date()
last30Days.setDate(today.getDate() - 30)
last30Days.setHours(0, 0, 0, 0)
const plusOneDay = new Date(today)
plusOneDay.setDate(today.getDate() + 1)
plusOneDay.setHours(0, 0, 0, 0)

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const queryParams = { ...to.params }
      if (!isEmpty(queryParams)) {
        if (queryParams.channel) {
          vm.currentChannel = queryParams.channel
          vm.searchByFilter(true)
        }
      }
    })
  },
  components: {
    PrintShipmentBacth,
    ReferenceIdSetter,
    'shipment-items': ShipmentItems,
    'shipment-batch-createoredit': ShipmentBatchCreateOrEdit,
    'add-cost-to-invoice': AddCostToBatch,
    'notification-preferences': NotificationPreferences,
    'service-folder-file-manager': () => import('@/components/service-folder/ServiceFolderFileManager.vue'),
  },
  mixins: [GridBase, Filters],
  data() {
    return {
      isIndividualTenant: false,
      disabledAddCost: false,
      isSearchAction: false,
      filters: {
        query: '',
      },
      ShipmentBatchsDataSource,
      tableData: [],
      selectedRow: {
        id: 0,
      },
      selectedRows: [],
      selectedDateRange: predefinedDateRangesEnum.LAST30DAYS.value,
      dateRangesList: getPredefinedDateRangesList(),
      beginDate: last30Days,
      endDate: plusOneDay,
      selectedStatus: 'ALL',
      counts: {
        batchCount: 0,
        itemCount: 0,
      },
      statuses: [
        { text: 'Status (All)', value: 'ALL' },
        { text: 'Not Created', value: 'NOT_CREATED_YET' },
        { text: 'In-Prep', value: 'IN_PREP' },
        { text: 'Pre-Ship', value: 'PRE_SHIP' },
        { text: 'Ready to Ship', value: 'READY_TO_SHIP' },
        { text: 'Shipped', value: 'SHIPPED' },
      ],
      selectedAmzStatus: 'ALL',
      amzStatuses: [],
      batchId: 0,
      storeName: '',
      batchName: '',
      shipmentPlanId: '',
      showBatchItems: false,
      showFoundQty: false,
      selectedData: {
        destCode: '',
        batchName: '',
      },
      bolUpload: {
        serviceName: 'BOL_OUTBOUND',
        companyId: null,
        batchId: null,
        batchName: null,
        documentClass: 'BATCH',
        documentClasses: ['BATCH'],
      },
      addCostToInvoiceComponentId: '',
      shippedItemsComponentId: '',
      shipmentBatchCreateOrEditId: '',
      hoveredNote: '',
      hoveredItem: '',
      isPopoverVisible: false,
      onlyNotesUpdate: false,
      currentChannel: '',
      dataGridState: {},
      searchText: '',
    }
  },
  setup() {
    const { setDateRanges, startDate, isCustomDateRange } = useDateRanges()
    const {
      userId,
      userCompanyId,
      userCompanyType,
      isServingTenant,
    } = useCurrentUser()
    return {
      setDateRanges, startDate, isCustomDateRange, userId, userCompanyId, isServingTenant, userCompanyType,
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    dataGrid() {
      const grid = this.$refs.shipmentBatchesRef.instance
      return grid
    },
    editActions() {
      return [
        {
          id: 'edit',
          btnId: 'btn-edit',
          visible: true,
          name: 'Edit',
          icon: 'edit',
          class: 'rounded-0 p-0 text-left',
          elementAttr: {
            class: 'p-0',
          },
        },
        {
          id: 'add-note',
          btnId: 'btn-add-note',
          visible: true,
          name: 'Add/Edit Note',
          icon: 'icon bi-stickies-fill',
          class: 'rounded-0 p-0 text-left',
          elementAttr: {
            class: 'p-0',
          },
        },
        {
          id: 'delete',
          btnId: 'btn-delete',
          visible: true,
          name: 'Delete',
          icon: 'trash',
          class: 'rounded-0 p-0 text-left',
          elementAttr: {
            class: 'p-0',
          },
        },
        {
          id: 'print',
          btnId: 'btn-print',
          visible: true,
          name: 'Print',
          icon: 'print',
          class: 'rounded-0 p-0 text-left',
          elementAttr: {
            class: 'p-0',
          },
        },
        {
          id: 'upload',
          btnId: 'btn-upload',
          visible: true,
          name: 'Upload BOL',
          icon: 'upload',
          class: 'rounded-0 p-0 text-left',
          elementAttr: {
            class: 'p-0',
          },
        },
      ]
    },
    downloadActions() {
      return [
        {
          id: 'inventoryLabDownload',
          btnId: 'btn-il',
          visible: true,
          name: 'Inventory Lab',
          icon: 'icon bi bi-graph-up-arrow',
          class: 'rounded-0 p-0 text-left',
        },
        {
          id: 'sellerBoardDownload',
          btnId: 'btn-seller-board',
          visible: true,
          icon: 'icon icon-feather-bar-chart-2',
          name: 'Seller Board',
          class: 'rounded-0 p-0 text-left',
        },
      ]
    },
  },
  watch: {
    selectedDateRange() {
      this.beginDate = this.startDate
    },
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData.tenantType === 'INDIVIDUAL') {
      this.isIndividualTenant = true
    }
    this.amzStatuses = getListAmzShipmentStatusEnum()
  },
  mounted() {
    this.getWarehouses()
    this.getStores(this.accountNo)
    this.loadData(true)
  },
  methods: {
    initialFilter(channel) {
      this.currentChannel = channel
    },
    getCurrencyFormat(data) {
      return currencyFormatter(data.value)
    },
    onOptionChanged(e) {
      if (e.fullName === 'paging.pageSize') {
        ShipmentBatchsDataSource.pageIndex(0)
      }
    },
    onCloseUploadPopupRef() {
      this.searchByFilter()
    },
    hasAdminPermission() {
      return this.$can('read', 'resource_admin')
    },
    downloadEnabled(data) {
      return data.data.downloadCount > 0 ? `Downloaded (${data.data.downloadCount})` : 'Download'
    },
    downloadCountButtonName(data) {
      return data.data.downloadCount > 0 ? `Downloaded (${data.data.downloadCount})` : 'Download'
    },
    getItemsDeliveredStatus(data) {
      if (data.data.deliveredQty === undefined) return 'badge-light-warning'
      if (data.data.deliveredQty === 0) return 'badge-danger'
      if (data.data.deliveredQty === data.data.itemCount) return 'badge-success'
      if (data.data.deliveredQty < data.data.itemCount) return 'badge-warning'
      return 'badge-primary'
    },
    allowDeleting(e) {
      return ((e.row.data.invoiceId && e.row.data.invoiceId !== null) || e.row.data.status !== 'IN_PREP' || e.row.data.source === 'FBA_FORCE')
    },
    allowBySourceType(e) {
      return !(e.row.data.source === 'FBA_FORCE')
    },
    allowAddCostToBatch(e) {
      return false // (e.row.data.invoiceId && e.row.data.invoiceId !== null)
    },
    isActionDisabled(data, id) {
      switch (id) {
        case 'edit':
          return this.allowEditing(data)
        case 'delete':
          return this.allowDeleting(data)
        case 'print':
          return this.allowBySourceType(data)
        case 'upload':
          return false
        case 'add-note':
          return false
        default:
          return true
      }
    },
    allowEditing(e) {
      return (e.row.data.invoiceId && e.row.data.invoiceId !== null) || e.row.data.status === 'SHIPPED' || e.row.data.source === 'FBA_FORCE'
    },
    getFormated(status) {
      if (status === 'IN_PREP') return { variant: 'badge-light-warning', text: 'IN-PREP' }
      if (status === 'PRE_SHIP') return { variant: 'badge-light-primary', text: 'PRE-SHIP' }
      if (status === 'READY_TO_SHIP') return { variant: 'badge-light-success', text: 'READY TO SHIP' }
      if (status === 'SHIPPED') return { variant: 'badge-success', text: 'SHIPPED' }
      if (status === 'NOT_CREATED_YET') return { variant: 'badge-light-secondary', text: 'NOT CREATED' }
      return { variant: 'badge-light-secondary', text: 'Other' }
    },
    getAmzFormated(status) {
      if (status === 'WORKING') return { variant: 'badge-light-primary', text: 'WORKING' }
      if (status === 'SHIPPED') return { variant: 'badge-success', text: 'SHIPPED' }
      if (status === 'RECEIVING') return { variant: 'badge-light-warning', text: 'RECEIVING' }
      if (status === 'CANCELLED') return { variant: 'badge-danger', text: 'CANCELLED' }
      if (status === 'DELETED') return { variant: 'badge-danger', text: 'DELETED' }
      if (status === 'CLOSED') return { variant: 'badge-secondary', text: 'CLOSED' }
      if (status === 'ERROR') return { variant: 'badge-danger', text: 'ERROR' }
      if (status === 'IN_TRANSIT') return { variant: 'badge-light-warning', text: 'IN_TRANSIT' }
      if (status === 'DELIVERED') return { variant: 'badge-light-success', text: 'DELIVERED' }
      if (status === 'CHECKED_IN') return { variant: 'badge-light-success', text: 'CHECKED_IN' }
      return { variant: 'primary', text: '' }
    },
    getShipmentTypeFormat(shipmentType) {
      if (shipmentType === 'LTL') return 'badge-light-success'
      if (shipmentType === 'SP') return 'badge-light-primary'
      return ''
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        this.isSearchAction = true
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.filters.query = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filters.query = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.filters.query = ''
            this.searchByFilter()
          }
        }
      }
    },
    async setActionByItem(item) {
      const popup = this.$refs.notificationPreferencesPopupRef.instance
      switch (item.id) {
        case 'notifPref':
          popup.show()
          break
        case 'resetDatagridState':
          this.resetGridState()
          break
        default:
          break
      }
    },
    async resetGridState() {
      if (this.currentGridStateId === null) {
        Notify.warning('This datagrid does not have any settings to reset.')
        return
      }
      await settingsService.delete(this.currentGridStateId)
      window.location.reload()
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.splice(0, toolbarItems.length)

      toolbarItems.unshift({
        location: 'before',
        template: 'filterToolbar',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          text: 'Export',
          icon: 'exportxlsx',
          items: [
            {
              id: 'selectedBatches',
              name: 'Batches',
              icon: 'exportselected',
            },
            {
              id: 'selectedBatchItem',
              name: 'Items',
              icon: 'exportselected',
            },
            {
              id: 'ilFormat',
              name: 'IL Merged',
              icon: 'icon bi bi-graph-up-arrow',
            },
            {
              id: 'sbFormat',
              name: 'SB Merged',
              icon: 'icon icon-feather-bar-chart-2',
            },
          ],
          width: 130,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Download All Inventory',
          onItemClick: arg => {
            if (arg.itemData.id === 'ilFormat') {
              const dataGrid = this.$refs.shipmentBatchesRef.instance
              const selectedRowsData = dataGrid.getSelectedRowsData()
              if (selectedRowsData.length === 0) {
                Notify.error('Please select at least one batch to download Inventory Lab file upload formatted file')
                return
              }
              this.saveAllItemsInILFormat(selectedRowsData)
            } else if (arg.itemData.id === 'sbFormat') {
              const dataGrid = this.$refs.shipmentBatchesRef.instance
              const selectedRowsData = dataGrid.getSelectedRowsData()
              if (selectedRowsData.length === 0) {
                Notify.error('Please select at least one batch to download Seller Board file upload formatted file')
                return
              }
              this.saveAllItemsInSBFormat(selectedRowsData)
            } else if (arg.itemData.id === 'selectedBatchItem') {
              const dataGrid = this.$refs.shipmentBatchesRef.instance
              const selectedRowKeysData = dataGrid.getSelectedRowKeys()
              if (selectedRowKeysData.length === 0) {
                Notify.error('Please select at least one batch to export items')
                return
              }
              outboundShippingService
                .fetchAllByMultipleBatchIds(selectedRowKeysData)
                .then(result => {
                  const { data } = result
                  if (data.length > 0) {
                    this.saveAllItemsInSameSheet(data)
                  }
                })
            } else if (arg.itemData.id === 'selectedBatches') {
              const dataGrid = this.$refs.shipmentBatchesRef.instance
              const selectedRowsData = dataGrid.getSelectedRowsData()
              if (selectedRowsData.length === 0) {
                Notify.error('Please select at least one batch')
                return
              }
              this.saveAllBatches(selectedRowsData)
            }
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          hint: 'Create new batch',
          visible: this.hasPermission || this.isIndividualTenant,
          icon: 'add',
          onClick: () => {
            this.selectedRow = {}
            this.selectedRow.id = 0
            this.shipmentBatchCreateOrEditId = uuidv4()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.clearShipmentPlanFilter()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxDropDownButton',
        options: {
          keyExpr: 'id',
          displayExpr: 'name',
          icon: 'menu',
          showArrowIcon: false,
          items: [
            {
              id: 'notifPref',
              name: 'Notification Preferences',
              icon: 'icon bi-bell-fill',
            },
            {
              id: 'resetDatagridState',
              name: 'Reset Datagrid Settings',
              icon: 'icon bi-x-square',
            },
          ],
          width: 36,
          type: 'default',
          stylingMode: 'contained',
          hint: 'Set Preferences',
          dropDownOptions: { width: 'auto' },
          dropDownContentTemplate: 'buttons',
        },
        location: 'after',
      })
    },
    loadData() {
      this.selectedDateRange = predefinedDateRangesEnum.LAST30DAYS.value
      ShipmentBatchFilter.setDefaultFilters()
      ShipmentBatchFilter.channel = this.currentChannel
      ShipmentBatchFilter.beginDate = last30Days
      ShipmentBatchFilter.endDate = plusOneDay
      this.shipmentPlanId = null
      ShipmentBatchFilter.shipmentPlanId = null
      const filters = ShipmentBatchFilter.getFilters()

      ShipmentBatchsDataSource.reload().then(() => {
        this.accountNo = filters.accountNo
        this.getStores()
      })
      this.fetchCountsBatchesByQuery(filters)
    },
    async searchByFilter(fetchCounts = true) {
      ShipmentBatchFilter.setDefaultFilters()
      ShipmentBatchFilter.accountNo = this.accountNo
      ShipmentBatchFilter.shipmentPlanId = this.shipmentPlanId
      ShipmentBatchFilter.warehouseId = this.selectedWarehouse
      ShipmentBatchFilter.storeId = this.selectedStore
      ShipmentBatchFilter.status = this.selectedStatus
      ShipmentBatchFilter.amzStatus = this.selectedAmzStatus === 'ALL' ? null : this.selectedAmzStatus
      ShipmentBatchFilter.beginDate = this.beginDate
      ShipmentBatchFilter.endDate = this.endDate
      ShipmentBatchFilter.channel = this.currentChannel
      ShipmentBatchFilter.productCode = this.filters.query !== '' && (isValidUpc(this.filters.query) || isValidAsin(this.filters.query))
      ShipmentBatchFilter.q = this.filters.query
      const filters = ShipmentBatchFilter.getFilters()
      this.showFoundQty = filters.productCode
      ShipmentBatchsDataSource.searchValue(filters)
      await ShipmentBatchsDataSource.reload()
      if (fetchCounts) {
        this.fetchCountsBatchesByQuery(filters)
      }
    },
    refreshAfterSettingRefID() {
      // this.dataGrid.clearSorting()
      this.searchByFilter()
    },
    onRowChanged() {
      this.searchByFilter()
    },
    onFilterByAccountNoLink(e, row) {
      this.accountNo = row.value.toString()
      this.getStores(this.accountNo)
      this.searchByFilter()
    },
    onShipmentPlanNameLinkClick(e, row) {
      this.shipmentPlanId = row.data.shipmentPlanId
      this.searchText = row.data.shipmentPlanName
      this.searchByFilter()
    },
    clearShipmentPlanFilter() {
      this.shipmentPlanId = null
      this.searchByFilter()
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value.toString()
          this.getStores(this.accountNo)
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = ''
        this.getStores()
        this.searchByFilter()
      }
    },
    getShipmentBatchDataByAccount(e) {
      if (e.event.keyCode !== 13) return
      const accountNo = e.event.target.value
      this.accountNo = accountNo
      this.getStores(accountNo)
      this.searchByFilter()
    },
    fetchCountsBatchesByQuery(filter) {
      outboundShippingService
        .fetchCountsBatchesByQuery(filter)
        .then(result => {
          const { data } = result
          this.counts = {
            batchCount: (data.batchCount) ? data.batchCount : 0,
            itemCount: (data.itemCount) ? data.itemCount : 0,
          }
        })
    },
    onBatchNameLinkClick(data, showBatchItems) {
      this.showBatchItems = showBatchItems
      this.batchId = data.data.id
      this.batchName = data.data.batchName
      this.shippedItemsComponentId = uuidv4()
    },
    onGetList(e) {
      const context = { ...e.row.data }
      this.$router.push({
        name: 'outbound-shipping-batch-item',
        query: {
          batchId: context.id,
          batchName: context.batchName,
          companyName: context.companyName,
          storeName: context.storeName,
        },
      })
    },
    onEditActionClink(e, row, id) {
      const { data } = row
      if (!id) return
      switch (id) {
        case 'edit':
          this.onEdit(data)
          break
        case 'delete':
          this.onDeleteBatch(data)
          break
        case 'print':
          this.onPrintBatch(data)
          break
        case 'upload':
          this.onUploadBOL(data)
          break
        case 'add-note':
          this.updateNote(data)
          break
        default:
          break
      }
    },
    onEdit(data) {
      this.selectedRow = data
      this.onlyNotesUpdate = false
      this.shipmentBatchCreateOrEditId = uuidv4()
    },
    updateNote(data) {
      this.selectedRow = data
      this.onlyNotesUpdate = true
      this.shipmentBatchCreateOrEditId = uuidv4()
    },
    onDeleteBatch(data) {
      this.$swal({
        title: 'Are you sure you want to delete this Batch record?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          outboundShippingService.deleteBatch(data.id).then(async () => {
            await this.searchByFilter()
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Item record has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        }
      })
    },
    onAddCostToBatch(e) {
      this.disabledAddCost = (e.row.data.invoiceId && e.row.data.invoiceId !== null)
      this.batchId = e.row.key
      this.batchName = e.row.data.batchName
      this.storeName = e.row.data.storeName
      this.addCostToInvoiceComponentId = uuidv4()
      // this.$root.$emit('bv::toggle::collapse', 'add-cost-to-invoice-sidebar')
    },
    saveAllBatches(data) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('FBA Batches')
      worksheet.columns = [
        { header: 'Batch Id', key: 'batchName', width: 16 },
        { header: 'AccountNo', key: 'accountNo', width: 15 },
        { header: 'Company', key: 'companyName', width: 30 },
        { header: 'Store', key: 'storeName', width: 30 },
        { header: 'SKU Count', key: 'skuCount', width: 14 },
        { header: 'Item Count', key: 'itemCount', width: 14 },
        { header: 'Prep Status', key: 'status', width: 12 },
        { header: 'Amazon Status', key: 'amzStatus', width: 14 },
        { header: 'Created At', key: 'creationTime', width: 12 },
        { header: 'Shipped At', key: 'shipTime', width: 12 },
        { header: 'Warehouse', key: 'warehouseName', width: 20 },
        { header: 'Dest Code', key: 'destCode', width: 20 },
        { header: 'Batch Charges', key: 'batchChargesTotal', width: 14 },
        { header: 'Shipping Cost', key: 'shippingCost', width: 14 },
      ]
      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:N1'
      worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 1 }]

      data.forEach(item => {
        worksheet.addRow([
          item.batchName,
          item.accountNo,
          item.companyName,
          item.storeName,
          item.skuCount,
          item.itemCount,
          item.status,
          item.amzStatus,
          formatDate(item.creationTime),
          formatDate(item.shipTime),
          item.warehouseName,
          item.destCode,
          item.batchChargesTotal,
          item.shippingCost,
        ])
      })
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'FBABatches.xlsx',
        )
      })
    },
    saveAllItemsInSameSheet(data) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('ShippedItems')
      worksheet.columns = [
        { header: 'Amazon Batch Id', key: 'batchName', width: 18 },
        { header: 'Title', key: 'title', width: 80 },
        { header: 'UPC', key: 'upc', width: 14 },
        { header: 'ASIN', key: 'asin', width: 14 },
        { header: 'FNSKU', key: 'fnsku', width: 14 },
        { header: 'MSKU', key: 'msku', width: 40 },
        { header: 'Tracking', key: 'tracking', width: 20 },
        { header: 'Order Number', key: 'orderNumber', width: 20 },
        { header: 'QTY', key: 'quantity', width: 8 },
        { header: 'Ship Date', key: 'shipTime', width: 12 },
      ]
      worksheet.getRow(0).font = {
        size: 16,
        bold: true,
      }
      worksheet.getRow(0).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
      worksheet.getRow(0).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D59C9C' },
      }
      worksheet.autoFilter = 'A1:J1'
      worksheet.views = [{ state: 'frozen', xSplit: 1, ySplit: 1 }]

      let currentBatchName = data[0].batchName
      data.forEach(item => {
        if (item.batchName !== currentBatchName) {
          currentBatchName = item.batchName
        }
        worksheet.addRow([
          item.batchName,
          item.title,
          item.upc,
          item.asin,
          item.fnsku,
          item.msku,
          item.tracking,
          item.orderNumber,
          item.quantity,
          formatDate(item.shipTime),
        ])
      })
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'ShippedItems.xlsx',
        )
      })
    },
    onDownloadItemClick(e, row, id) {
      const { data } = row
      if (!id) return
      switch (id) {
        case 'inventoryLabDownload':
          this.getILFormattedDataSingle(data)
          break
        case 'sellerBoardDownload':
          this.getSBFormattedDataSingle(data)
          break
        default:
          break
      }
    },
    saveAllItemsInILFormat(batches) {
      let notDownloadableBatch = false
      batches.forEach(batch => {
        if (batch.parentStatus !== 'closed') {
          Notify.error(`This batch is not ready for export. Please check back later. Shipment Batch: ${batch.batchName}`)
          notDownloadableBatch = true
        }
      })
      if (notDownloadableBatch) {
        return
      }
      const batchName = batches.length > 1 ? 'Merged' : batches[0].batchName
      const batchIds = batches.map(k => k.id)
      const date = new Date().toDateString()
      outboundShippingService
        .getILFormattedItemsBatchIds(batchIds)
        .then(result => {
          const { data } = result
          if (data.length > 0) {
            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('BatchItems')
            worksheet.columns = [
              { header: 'ASIN', key: 'asin', width: 13 },
              { header: 'TITLE', key: 'title', width: 80 },
              { header: 'COSTUNIT', key: 'cost', width: 10 },
              { header: 'LISTPRICE', key: 'listPrice', width: 10 },
              { header: 'QUANTITY', key: 'quantity', width: 8 },
              { header: 'PURCHASEDDATE', key: 'shipTime', width: 12 },
              { header: 'SUPPLIER', key: 'supplier', width: 20 },
              { header: 'CONDITION', key: 'condition', width: 10 },
              { header: 'MSKU', key: 'msku', width: 30 },
              // { header: 'INVENTORY SKU', key: 'inventorySku', width: 30 },
              { header: 'PALLET ID', key: 'palletId', width: 30 },
              { header: 'SALESTAX', key: 'salesTax', width: 8 },
              { header: 'DISCOUNT', key: 'discount', width: 10 },
              { header: 'EXPIRATIONDATE', key: 'expiration', width: 14 },
              { header: 'TAXCODE', key: 'taxCode', width: 10 },
              { header: 'MINPRICE', key: 'listPrice', width: 10 },
              { header: 'MAXPRICE', key: 'listPrice', width: 10 },
              { header: 'MISSING IN THE SYSTEM', key: 'missingCount', width: 10 },
            ]
            data.forEach(item => {
              worksheet.addRow([
                item.asin,
                item.title,
                item.cost,
                item.listPrice,
                item.quantity,
                formatDate(item.shipTime),
                item.supplier,
                item.condition,
                item.msku,
                // item.inventorySku,
                item.palletId,
                item.salesTax,
                item.discount,
                formatDate(item.expiration),
                item.taxCode,
                item.listPrice,
                item.listPrice,
                item.clientBatchStatus === 'needs_action' ? item.quantity : '',
              ])
            })
            workbook.csv.writeBuffer().then(buffer => {
              saveAs(
                new Blob([buffer], { type: 'application/octet-stream' }),
                `IL-${batchName} ${date}.csv`,
              )
            })
          }
        }).finally(() => {
          this.searchByFilter(false)
        })
    },
    getILFormattedDataSingle(batch) {
      const batches = [batch]
      this.saveAllItemsInILFormat(batches)
    },
    async saveAllItemsInSBFormat(batches) {
      let notDownloadableBatch = false
      batches.forEach(batch => {
        if (batch.parentStatus !== 'closed') {
          Notify.error(`This batch is not ready for export. Please check back later. Shipment Batch: ${batch.batchName}`)
          notDownloadableBatch = true
        }
      })
      if (notDownloadableBatch) {
        return
      }
      const batchName = batches.length > 1 ? 'Merged' : batches[0].batchName
      const batchIds = batches.map(k => k.id)
      const date = new Date().toDateString()
      outboundShippingService
        .getILFormattedItemsBatchIds(batchIds)
        .then(result => {
          const { data } = result
          if (data.length > 0) {
            const workbook = new ExcelJS.Workbook()
            const worksheet = workbook.addWorksheet('BatchItems')
            worksheet.columns = [
              { header: 'ASIN', key: 'asin', width: 13 },
              { header: 'SKU', key: 'msku', width: 30 },
              { header: 'Title', key: 'title', width: 80 },
              { header: 'Labels', key: 'label', width: 80 },
              { header: 'CostPeriodStartDate', key: 'costPeriodStartDate', width: 80 },
              { header: 'Cost', key: 'cost', width: 10 },
              { header: 'ShippingCostPerOrder', key: 'shippingCostPerOrder', width: 10 },
              { header: 'SurchargeForShippingAbroad', key: 'surchargeForShippingAbroad', width: 10 },
              { header: 'Value_of_unsellable_returns', key: 'value_of_unsellable_returns', width: 10 },
              { header: 'VAT', key: 'vat', width: 10 },
              { header: 'VAT_DE_2020', key: 'vat_de_2020', width: 10 },
              { header: 'VAT_CATEGORY', key: 'vat_category', width: 10 },
              { header: 'Hide', key: 'hide', width: 10 },
              { header: 'Marketplace', key: 'marketplace', width: 10 },
            ]
            data.forEach(item => {
              worksheet.addRow([
                item.asin,
                item.msku,
                item.title,
                '#FBA',
                formatDate(item.shipTime),
                item.cost,
                '',
                '',
                '',
                '',
                '',
                'A_GEN_STANDARD',
                'NO',
                'Amazon.com',
              ])
            })
            workbook.csv.writeBuffer().then(buffer => {
              saveAs(
                new Blob([buffer], { type: 'application/octet-stream' }),
                `SB-${batchName} ${date}.csv`,
              )
            })
          }
        }).finally(() => {
          this.searchByFilter(false)
        })
    },
    getSBFormattedDataSingle(batch) {
      const batches = [batch]
      this.saveAllItemsInSBFormat(batches)
    },
    saveEachBatchInSeparateSheet(data) {
      const workbook = new ExcelJS.Workbook()
      data.forEach(item => {
        if (!workbook.getWorksheet(item.batchName)) {
          workbook
            .addWorksheet(item.batchName)
            .addRow([
              'Title',
              'UPC',
              'ASIN',
              'Quantity',
              'Ship Date',
              'Total Charge',
            ])
        }
        const worksheet = workbook.getWorksheet(item.batchName)
        worksheet.addRow([
          item.productTitle,
          item.upc,
          item.asin,
          item.quantity,
          formatDate(item.shipTime),
          item.totalCharge,
        ])
      })
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'ShippedItems.xlsx',
        )
      })
    },
    onPrintBatch(data) {
      this.batchId = data.id
      this.selectedData.destCode = data.destCode
      this.selectedData.batchName = data.batchName
      const popup = this.$refs.printShipmentBacthPopupRef.instance
      popup.show()
    },
    onShownPrintShipmentBacthPopup() {
      this.batchName = `${this.selectedData.destCode}-${this.selectedData.batchName}`
    },
    onUploadBOL(data) {
      const { companyId, id: batchId, batchName } = data
      this.bolUpload.companyId = companyId
      this.bolUpload.batchId = batchId.toString()
      this.bolUpload.batchName = batchName
      this.$refs.fileUploadPopupRef.instance.show()
    },
    togglePopover(data) {
      const rowId = data.data.id
      const notes = data.data.notes
      this.hoveredItem = `#notes-${rowId}`
      this.hoveredNote = notes
      this.isPopoverVisible = !this.isPopoverVisible
    },
    async loadDataGridState(e) {
      const dataGridKey = DataGridNamesEnum.OUTBOUND_SHIPMENT_BATCHES_GRID.key
      const result = await settingsService.getByReference(referenceTypeEnum.USER.value, this.userId, dataGridKey)
      this.currentGridStateId = result.data.id
      this.dataGridState = {}
      if (result.data.value) {
        const state = JSON.parse(result.data.value)
        state.searchText = ''
        this.dataGridState = state
      }
      return this.dataGridState
    },
    async saveDataGridState(e) {
      if (e === undefined || e === null) {
        return
      }
      if (JSON.stringify(e) === JSON.stringify(this.dataGridState)) {
        return
      }
      if (e.searchText !== '' || this.isSearchAction) {
        this.isSearchAction = false
        return
      }
      const dataGridPreferences = { ...e }
      dataGridPreferences.selectedRowKeys = []
      dataGridPreferences.searchText = ''
      const dataGridKey = DataGridNamesEnum.OUTBOUND_SHIPMENT_BATCHES_GRID.key
      const preferenceData = {
        companyId: this.userCompanyId,
        referenceId: this.userId,
        referenceType: referenceTypeEnum.USER.value,
        settingType: SettingsTypeEnum.DATAGRID_STATES.value,
        settingKey: dataGridKey,
        description: `State of the ${dataGridKey} for UserId: ${this.userId}`,
        settingName: dataGridKey,
        value: JSON.stringify(dataGridPreferences),
      }
      const result = await settingsService.createOrUpdate(preferenceData)
      this.currentGridStateId = result.data.body.id
    },
  },
}
</script>

<style lang="scss">
.dx-item.dx-toolbar-item.dx-toolbar-label {
    max-width: unset !important;
  }
#btn-edit {
  .dx-icon {
    font-size: 1.3rem;
    color: #e8a03c;
    margin-right: 0.5rem;
  }
}
#btn-add-note {
  .dx-icon {
    font-size: 1.3rem;
    color: #74a4a5;
    margin-right: 0.5rem;
  }
}

#btn-delete {
  .dx-icon {
    font-size: 1.3rem;
    color: #d75645;
    margin-right: 0.5rem;
  }
}
#btn-print {
  .dx-icon {
    font-size: 1.3rem;
    color: #417fb4;
    margin-right: 0.5rem;
  }
}
#btn-upload {
  .dx-icon {
    font-size: 1.3rem;
    color: #55ab68;
    margin-right: 0.5rem;
  }
}
#editActions  {
  .dx-icon {
    font-size: 1.143rem;
    color: #FFFFFF;
    margin-right: 0.5rem;
  }
  .dx-button-content {
    text-align: left;
    background-color: #e8a03c;
  }
  .dx-buttongroup-item {
    padding-left: 0px;
    padding-right: 1px;
  }
  .dx-dropdownbutton-action:not(.dx-buttongroup-last-item).dx-button {
    border-right-width: 0;
  }
}

.green-color .dx-button-content {
 text-align: center;
 color: #03a647;
 border: #03a647 1px solid;
}

#btn-seller-board {
  color: #F5AE20;
  .dx-icon {
    font-size: 1.3rem;
    color: #F5AE20;
    margin-right: 0.5rem;
  }
}

#btn-il {
  color: #7ED4FA;
  .dx-icon {
    font-size: 1.3rem;
    color: #7ED4FA;
    margin-right: 0.5rem;
  }
}
</style>
