/* eslint-disable no-param-reassign */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import outboundShippingService from '@/http/requests/outbound/outboundShippingService'
import Pager from '@core/dev-extreme/utils/pager'

function sortFieldMap(sort) {
  const sortBy = []
  sort.forEach(c => {
    let field = c.selector
    // if (c.selector === 'itemCount') field = 'quantity'
    if (c.selector === 'creationTime') field = 'id'
    sortBy.push({ selector: field, desc: c.desc })
  })
  return sortBy
}

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  load: loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? sortFieldMap(loadOptions.sort) : [{ selector: 'id', desc: true }]
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    pager.setSortQuery(sort)

    const pageableQuery = `${pager.toCreatePageable()}&sort=${pager.toCreateSortOrder()}`
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    if (filters === null) return {}
    return outboundShippingService
      .fetchBatchesByQuery(filters, pageableQuery)
      .then(response => {
        const data = response.data
        return {
          data: data.content,
          totalCount: data.totalElements,
        }
      })
  },
})

const shipmentBatchsDataSource = new DataSource({
  store: store,
  requireTotalCount: true,
})

export {
  shipmentBatchsDataSource as ShipmentBatchsDataSource,
}
