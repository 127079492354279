import BaseSearchFilter from './BaseSearchFilter'

class ShipmentBatchFilter extends BaseSearchFilter {
  /** @type {string} */
  #status = 'ALL'

  #amzStatus = null

  #channel = 'all'

  #shipmentPlanId = null

  constructor() {
    super()
    this.#status = this.status
    this.#amzStatus = this.amzStatus
    this.#shipmentPlanId = this.shipmentPlanId
    this.#channel = this.channel
  }

  setDefaultFilters() {
    this.accountNo = ''
    this.companyId = 0
    this.storeId = 0
    this.warehouseId = 0
    this.status = 'ALL'
    this.amzStatus = null
    this.beginDate = null
    this.endDate = null
    this.tenantType = 'SERVING'
    this.productCode = false
    this.shipmentPlanId = 0
    this.channel = 'all'
    this.q = ''
  }

  /**
   * The complete UploadedBatchFilter get filters.
   * @typedef {Object} filters
   * @property {number} accountNo - Account Number.
   * @property {number} companyId - Company Id.
   * @property {number} storeId - Store Id.
   * @property {number} warehouseId - Store Id.
   * @property {any} beginDate - Begin date.
   * @property {any} endDate - End date.
   * @property {string} tenantType - Tenant Type.
   * @property {boolean} productCode - Is Croduct Code.
   * @property {string} q - Query.
   * @property {number} shipmentPlanId - shipmentPlanId.
   * @property {string} status - Status.
   * @property {string} amzStatus - amzStatus.
   */

  /** @returns {filters} {@link filters} objects */
  getFilters() {
    const filter = {
      accountNo: this.accountNo,
      companyId: this.companyId,
      storeId: this.storeId,
      warehouseId: this.warehouseId,
      status: this.status,
      amzStatus: this.amzStatus,
      beginDate: this.beginDate,
      endDate: this.endDate,
      tenantType: this.tenantType,
      productCode: this.productCode,
      shipmentPlanId: this.shipmentPlanId,
      channel: this.channel,
      q: this.q,
    }
    return filter
  }

  /** @returns {string} */
  get status() {
    return this.#status
  }

  /** @param {string} value */
  set status(value) {
    this.#status = value
  }

  /** @returns {string} */
  get amzStatus() {
    return this.#amzStatus
  }

  /** @param {string} value */
  set amzStatus(value) {
    this.#amzStatus = value
  }

  /** @returns {number} */
  get shipmentPlanId() {
    return this.#shipmentPlanId
  }

  /** @param {number} value */
  set shipmentPlanId(value) {
    this.#shipmentPlanId = value
  }

  /** @returns {string} */
  get channel() {
    return this.#channel
  }

  /** @param {string} value */
  set channel(value) {
    this.#channel = value
  }
}

export default new ShipmentBatchFilter()
