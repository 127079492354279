<template>
  <dx-util-popup
    ref="addCostToBatchPopupRef"
    :show-close-button="true"
    :drag-enabled="true"
    :close-on-outside-click="false"
    :show-title="true"
    :full-screen="false"
    height="auto"
    :width="calculateWidth"
    :title="addCostToBatchPopupTitle"
    @shown="onShownAddCostToBatchPopup"
    @hidden="onHiddenAddCostToBatchPopup"
  >
    <div class="bg-light-primary d-flex justify-content-between mb-1 rounded">
      <div class="px-1 text-center">
        <small class="text-muted">Batch Name</small>
        <h5 v-for="(batch, index) in currentBatches" :key="index" class="">{{ batch.batchName }}
        </h5>
      </div>
      <div class="px-1 text-center">
        <small class="text-muted">Store</small>
        <h5 v-for="(batch, index) in currentBatches" :key="index" class="">{{ batch.storeName }}
        </h5>
      </div>
    </div>
    <div>
      <charge-item v-if="showCostComponent" :old-charge-items="chargeItems" :item-id="formData.id" :is-disabled="!hasPermission" :visible-default-charge="false" />
    </div>
    <div class="d-flex p-1 justify-content-end">
      <dx-util-button type="danger" text="Cancel" class="mr-1" style="min-width:100px" @click="onClose" />
      <dx-util-button v-if="hasPermission" type="success" text="Save" class="" style="min-width:100px" @click="onSubmit" />
    </div>
  </dx-util-popup>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import { Form } from '@robustshell/mixins/index'
import { Notify } from '@robustshell/utils/index'
import itemChargeService from '@/http/requests/finance/itemChargeService'
import ChargeItem from '@components/charge/TheChargeItem.vue'
import store from '@/store'
import chargeStoreModule from '@components/charge/chargeStoreModule'
import { useWindowSize } from '@vueuse/core'

const CHARGE_STORE_MODULE = 'charge'
if (!store.hasModule(CHARGE_STORE_MODULE)) store.registerModule(CHARGE_STORE_MODULE, chargeStoreModule)

export default {
  components: {
    ChargeItem,
  },
  mixins: [Form, heightTransition],
  props: {
    storeName: {
      type: String,
      required: true,
    },
    batchName: {
      type: String,
      required: true,
    },
    batchId: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      require: true,
    },
    componentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      showCostComponent: false,
      currentBatches: [],
      chargeItems: [],
      addCostToBatchPopupTitle: '',
    }
  },
  setup() {
    const { width: windowWidth } = useWindowSize()
    return { windowWidth }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    addCostToBatchPopupIns() {
      return this.$refs.addCostToBatchPopupRef.instance
    },
    calculateWidth() {
      let adjustedWidth = ''
      if (this.windowWidth < 400) {
        adjustedWidth = '95vw'
      } if (this.windowWidth >= 400 && this.windowWidth <= 1200) {
        adjustedWidth = '75vw'
      } if (this.windowWidth > 1200 && this.windowWidth <= 1400) {
        adjustedWidth = '60vw'
      } if (this.windowWidth > 1400) {
        adjustedWidth = '50vw'
      }
      return adjustedWidth
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler(current) {
        if (current) {
          this.createAddCostToBatchPopup()
        }
      },
    },
  },
  methods: {
    createAddCostToBatchPopup() {
      this.$nextTick(() => {
        this.addCostToBatchPopupIns.show()
      })
    },
    onShownAddCostToBatchPopup() {
      this.addCostToBatchPopupTitle = `Assing Charges to Shipment Batch #${this.batchId}`
      this.getChargeItems()
      this.currentBatches.splice(0, this.currentBatches.length)
      this.currentBatches.push({
        storeName: this.storeName,
        batchName: this.batchName,
      })
    },
    onHiddenAddCostToBatchPopup() {
      this.showCostComponent = false
      this.chargeItems.splice(0, this.chargeItems.length)
    },
    onSubmit() {
      store.dispatch('charge/setCharges', this.batchId)
      const chargeItems = store.getters['charge/getCharges']
      const getChargeErrors = store.getters['charge/getChargeErrors']
      if (getChargeErrors.length > 0) {
        Notify.danger(getChargeErrors.join(','))
      } else {
        store.dispatch('charge/clearCharges')
        itemChargeService.create(this.batchId, chargeItems).then(() => {
          this.showCostComponent = false
          this.onClose()
        })
      }
    },
    onClose() {
      this.addCostToBatchPopupIns.hide()
    },
    getChargeItems() {
      itemChargeService
        .fetchAllByItemId(this.batchId)
        .then(result => {
          const { data } = result
          data.forEach(charge => {
            this.chargeItems.push(charge)
          })
          this.showCostComponent = true
        })
    },
    onSidebarHidden() {
      this.showCostComponent = false
      this.chargeItems.splice(0, this.chargeItems.length)
    },
  },
}
</script>

<style lang="scss">
</style>
