<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <div v-for="(item, index) in boxes" :key="index" class="row px-0 mt-1">
          <div v-show="index === 0" class="form-group col-6 mb-0 px-half">
            <dx-util-select-box
              id="printer-select-box"
              v-model="selectedPrinter"
              :data-source="printers"
              display-expr="Name"
            />
          </div>
          <div v-show="index === 0" class="form-group col-6 mb-0 px-half">
            <dx-util-button
              icon="print"
              type="default"
              text="Print All"
              width="100%"
              :element-attr="{ class: 'text-left' }"
              @click="onPrintAllBarcodes($event, item)"
            />
          </div>
          <div class="form-group col-6 mb-0 px-half">
            <label v-show="index === 0" for="text">Boxes</label>
            <div
              class="form-control bg-success text-white rounded border-0"
              style="height: 2.571rem; white-space: nowrap"
            >
              <b-icon icon="box" />
              Box {{ item.boxName }}
            </div>
          </div>
          <div class="col-2 mb-0 px-half">
            <label v-show="index === 0" for="text">Download</label>
            <dx-util-button
              icon="print"
              type="success"
              text="Download"
              width="100%"
              :element-attr="{class: 'text-left'}"
              @click="onDownloadBarcode($event, item)"
            />
          </div>
          <div class="col-4 mb-0 px-half">
            <label v-show="index === 0" for="text">Print 2D Content Barcode</label>
            <dx-util-button
              icon="print"
              type="default"
              text="Print"
              width="100%"
              :element-attr="{class: 'text-left'}"
              @click="onPrintBarcode($event, item)"
            />
          </div>
        </div>
      </div>
      <div class="col-6 mt-1">
        <app-timeline>
          <app-timeline-item v-show="isPartnered" icon="BoxIcon">
            <div
              class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
            >
              <h6>Estimate Shipping Charges</h6>
              <small class="text-muted">{{ transportStatusEnums.WORKING.text }}</small>
            </div>
            <div class="d-flex flex-column flex-wrap justify-content-start">
              <p>
                Click on Estimate Button to calculate estimated shipping cost.
              </p>
            </div>
            <b-button
              size="sm"
              variant="success"
              :disabled="true"
            >
              Estimate
            </b-button>
          </app-timeline-item>
          <app-timeline-item
            v-show="isPartnered"
            variant="secondary"
            icon="ClockIcon"
          >
            <div
              class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
            >
              <h6>Estimating</h6>
              <small class="text-muted">{{ transportStatusEnums.ESTIMATING.text }}</small>
            </div>
            <b-button
              size="sm"
              variant="outline-secondary"
              :disabled="true"
            >
              Check Estimate Status
            </b-button>
          </app-timeline-item>
          <app-timeline-item
            v-show="isPartnered"
            variant="success"
            icon="CheckCircleIcon"
          >
            <div
              class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
            >
              <h6>Shipping Charges</h6>
              <small class="text-muted">{{ transportStatusEnums.ESTIMATED.text }}</small>
            </div>
            <div class="d-flex flex-column flex-wrap justify-content-start">
              <p>Shipping carrier service UNITED PARCEL SERVICE INC</p>
              <p>
                Estimated shipping cost
                <span class="font-weight-bolder text-warning">{{ estimateShipmentCharges.value }}$</span>
              </p>
            </div>
            <b-button
              :disabled="true"
              size="sm"
              variant="primary"
              @click="onClickConfirmTransport()"
            >
              Accept Charges
            </b-button>
          </app-timeline-item>
          <app-timeline-item
            v-show="isPartnered"
            variant="warning"
            icon="ClockIcon"
          >
            <div
              class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
            >
              <h6>Confirming</h6>
              <small class="text-muted">{{
                transportStatusEnums.CONFIRMING.text
              }}</small>
            </div>
            <b-button
              size="sm"
              variant="outline-secondary"
              :disabled="true"
            >
              Check Confirm Status
            </b-button>
          </app-timeline-item>
          <app-timeline-item v-show="isPartnered" variant="danger" icon="MinusCircleIcon">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Void Labels</h6>
              <small class="text-muted">{{ transportStatusEnums.CONFIRMED.text }}</small>
            </div>
            <b-button
              size="sm"
              variant="danger"
              :disabled="true"
            >
              Void Labels {{ voidDate.disabled ? '' : voidDate.counter }}
            </b-button>
          </app-timeline-item>
          <app-timeline-item variant="warning" icon="DownloadIcon">
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>Shipping Labels</h6>
              <small class="text-muted">{{ transportStatusEnums.CONFIRMED.text }}</small>
            </div>
            <b-button
              size="sm"
              class="mr-1"
              variant="warning"
              :disabled="transportStatus !== transportStatusEnums.CONFIRMED.key"
              @click="getFbaShipmentLabels(shipmentBatchId)"
            >
              Download Shipping Labels
            </b-button>
          </app-timeline-item>
          <app-timeline-item variant="success" icon="CheckCircleIcon">
            <div
              class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
            >
              <h6>Mark as Shipped</h6>
              <small class="text-muted">{{
                transportStatusEnums.CONFIRMED.text
              }}</small>
            </div>
            <b-button
              size="sm"
              class="mr-1"
              variant="success"
              :disabled="transportStatus !== transportStatusEnums.CONFIRMED.key && isPartnered"
              @click="markAsShipped(shipmentBatchId)"
            >
              Mark as Shipped
            </b-button>
          </app-timeline-item>
        </app-timeline>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import moment from 'moment'
import fbaShipmentService from '@/http/requests/outbound/fbaShipmentService'
import outboundShippingService from '@/http/requests/outbound/outboundShippingService'
import { Notify } from '@robustshell/utils'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import transportStatusEnum, { getTransportStatusEnum } from '@/enums/transportStatusEnum'
import { confirm } from 'devextreme/ui/dialog'
import usePrinter from '@/libs/printer/print'
import printerVarabiles, { labelTypes } from '@/libs/printer/printer-varaibles'
import { LabelParam } from '@/http/models/printer/entity/labelparam'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    shipmentBatchId: {
      type: Number,
      required: true,
    },
    shipmentBatchName: {
      type: String,
      required: true,
    },
  },
  setup() {
    const {
      selectedPrinter,
      printers,
      pshBase64,
      printStaticLabel,
    } = usePrinter()
    return {
      selectedPrinter,
      printers,
      pshBase64,
      printStaticLabel,
    }
  },
  data() {
    return {
      boxes: [],
      transportStatus: '',
      transportStatusEnums: { ...transportStatusEnum },
      transportHeader: {},
      estimateShipmentCharges: {
        currencyCode: '',
        value: 0.0,
      },
      currentPrinter: {},
      isPartnered: false,
      voidDate: {
        counter: null,
        disabled: true,
      },
    }
  },
  computed: {
    currentTransportStatus: {
      get() {
        return `${this.transportStatus}`
      },
      set(status) {
        this.transportStatus = status
      },
    },
  },
  watch: {
    shipmentBatchId: {
      immediate: true,
      async handler(current, previous) {
        if (current && current > 0) {
          this.getBatchBoxes(current)
          this.getTransportDetails(current)
        }
      },
    },
  },
  mounted() {
    this.setPrinters()
  },
  methods: {
    getBatchBoxes(id) {
      const parsed = parseInt(id, 10)
      fbaShipmentService.findAllBoxesByBatchId(parsed).then(result => {
        this.boxes.splice(0, this.boxes.length)
        const { data } = result
        data.forEach(item => {
          const boxDimension = {
            boxId: item.id,
            boxName: item.boxName,
            barcodeText: `${this.shipmentBatchName}-${item.boxName}`,
          }
          this.boxes.push(boxDimension)
        })
      })
    },
    getTransportDetails(id) {
      fbaShipmentService.getTransportDetails(id).then(result => {
        const {
          transportDetails,
          transportHeader,
          transportResult,
        } = result.data.payload.transportContent
        const { transportStatus } = transportResult
        this.transportHeader = transportHeader
        this.isPartnered = transportHeader.isPartnered
        if (transportDetails.partneredSmallParcelData && Object.keys(transportDetails.partneredSmallParcelData.partneredEstimate).length > 0
        ) {
          const {
            partneredEstimate,
          } = transportDetails.partneredSmallParcelData
          this.estimateShipmentCharges = partneredEstimate.amount
          if (partneredEstimate.voidDeadline) {
            const voidDeadline = moment
              .utc(partneredEstimate.voidDeadline)
              .local()
              .format('YYYY-MM-DD HH:mm:ss')
            if (transportStatus === this.transportStatusEnums.CONFIRMED.key) {
              this.voidLabelDateAge(voidDeadline)
            }
          }
        }
        this.currentTransportStatus = transportStatus
      })
    },
    voidLabelDateAge(voidDeadline) {
      const now = moment(moment().format('YYYY-MM-DD HH:mm:ss'))
      const result = moment.duration(moment(voidDeadline).diff(now))
      const diff = Math.trunc(result.asSeconds())
      if (diff <= 0) {
        this.voidLabelDateAgeEnd()
        this.voidDate.disabled = true
      }
      this.voidDate.disabled = false
      this.voidDate.counter = moment
        .utc(result.asMilliseconds())
        .format('hh:mm:ss')
    },
    voidLabelDateAgeEnd() {
      this.voidDate.disabled = true
      this.voidDate.counter = ''
    },
    getFbaShipmentLabels(id) {
      fbaShipmentService.getFbaShipmentLabels(id).then(result => {
        const { data } = result
        const link = document.createElement('a')
        link.href = data.payload.downloadURL
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    onClickConfirmTransport() {
      fbaShipmentService.confirmTransport(this.shipmentBatchId).then(result => {
        const { data } = result
        this.currentTransportStatus = data.transportStatus
      })
    },
    markAsShipped(id) {
      outboundShippingService.markAsShipped(id)
    },
    voidFbaShipmentLabel(id) {
      const result = confirm('<b>Are you sure you want o void this label?</b>', 'Void Label')
      result.then(async dialogResult => {
        if (dialogResult === 'Confirmed') {
          await fbaShipmentService.voidFbaShipmentLabel(id)
          this.voidDate = {
            counter: '',
            disabled: true,
          }
          Notify.success('Label is voided')
          this.getTransportDetails(id)
        }
      })
    },
    // #region print
    createPrinterParams(data) {
      const params = []
      params.push(new LabelParam(printerVarabiles.SHIPPING_PLAN_NAME.value, data.shippingPlanName))
      params.push(new LabelParam(printerVarabiles.CREATED_AT.value, moment(data.createdAt).format('YYYY-MM-DD HH:mm')))
      params.push(new LabelParam(printerVarabiles.BATCH_AND_BOX_NAME.value, data.batchAndBoxName))
      params.push(new LabelParam(printerVarabiles.ITEM_QTY.value, data.itemQty))
      params.push(new LabelParam(printerVarabiles.SKU_QTY.value, data.skuQty))
      params.push(new LabelParam(printerVarabiles.BARCODE_CONTENT.value, data.barcode))
      params.push(new LabelParam(printerVarabiles.CURRENT_DATE.value, moment(new Date()).format('YYYY-MM-DD')))
      params.push(new LabelParam(printerVarabiles.PSH_BASE64_CONST.value, this.pshBase64))
      params.push(new LabelParam(printerVarabiles.SHIP_FROM_LINE1.value, data.shipFrom[0]))
      params.push(new LabelParam(printerVarabiles.SHIP_FROM_LINE2.value, data.shipFrom[1]))
      params.push(new LabelParam(printerVarabiles.SHIP_FROM_LINE3.value, data.shipFrom[2]))
      params.push(new LabelParam(printerVarabiles.SHIP_FROM_LINE4.value, data.shipFrom[3]))
      params.push(new LabelParam(printerVarabiles.SHIP_FROM_LINE5.value, data.shipFrom[4]))
      params.push(new LabelParam(printerVarabiles.SHIP_FROM_LINE6.value, data.shipFrom[5]))
      params.push(new LabelParam(printerVarabiles.SHIP_TO_LINE1.value, data.shipTo[0]))
      params.push(new LabelParam(printerVarabiles.SHIP_TO_LINE2.value, data.shipTo[1]))
      params.push(new LabelParam(printerVarabiles.SHIP_TO_LINE3.value, data.shipTo[2]))
      params.push(new LabelParam(printerVarabiles.SHIP_TO_LINE4.value, data.shipTo[3]))
      params.push(new LabelParam(printerVarabiles.SHIP_TO_LINE5.value, data.shipTo[4]))
      params.push(new LabelParam(printerVarabiles.SHIP_TO_LINE6.value, data.shipTo[5]))
      return params
    },
    async onPrintAllBarcodes() {
      if (!this.selectedPrinter) {
        Notify.warning('Select a printer')
        return
      }
      try {
        const data = await fbaShipmentService.getBoxContentFor2DBarcodeAllBatch(this.shipmentBatchId)
        data.forEach(item => {
          const params = this.createPrinterParams(item)
          this.printStaticLabel(this.selectedPrinter.VendorName, this.selectedPrinter.Address, labelTypes.CONTENT_BARCODE_LABEL.value, params)
        })
      } catch (err) {
        const messeage = err.message || err
        Notify.error(messeage)
      }
    },
    async onPrintBarcode(e, item) {
      if (!this.selectedPrinter) {
        Notify.warning('Select a printer')
        return
      }
      const result = await fbaShipmentService.getBoxContentFor2DBarcode(item.boxId)
      try {
        const data = result.data
        const params = this.createPrinterParams(data)
        this.printStaticLabel(this.selectedPrinter.VendorName, this.selectedPrinter.Address, labelTypes.CONTENT_BARCODE_LABEL.value, params)
      } catch (err) {
        const messeage = err.message || err
        Notify.error(messeage)
      }
    },
    async onDownloadBarcode(e, item) {
      const result = await fbaShipmentService.getBoxContentFor2DBarcode(item.boxId)
      try {
        const data = result.data
        const barcodeLabelInfo = {
          shipFrom: data.shipFrom,
          shipTo: data.shipTo,
          shippingPlanName: data.shippingPlanName,
          createdAt: data.createdAt,
          batchAndBoxName: data.batchAndBoxName,
          batchName: data.batchName,
          boxName: data.boxName,
          itemQty: data.itemQty,
          skuQty: data.skuQty,
          barcode: data.barcode,
        }
        // const imgSrc = previewContentLabel(data.barcode, barcodeLabelInfo)
        // const link = document.createElement('a')
        // link.href = imgSrc
        // link.target = '_blank'
        // link.download = `${barcodeLabelInfo.batchName}.png`
        // link.click()
        // link.remove()
      } catch (err) {
        const messeage = err.message || err
        Notify.error(messeage)
      }
    },
    // #endregion

  },
}
</script>
